export var ClientCommand;
(function (ClientCommand) {
    ClientCommand["move"] = "MOVE";
})(ClientCommand || (ClientCommand = {}));
export var ServerCommand;
(function (ServerCommand) {
    // sends the current board to the player
    ServerCommand["board"] = "BOARD";
    // sends the which player they are
    ServerCommand["player"] = "PLAYER";
})(ServerCommand || (ServerCommand = {}));
