export var Player;
(function (Player) {
    Player["X"] = "X";
    Player["O"] = "O";
})(Player || (Player = {}));
export var PlayerMove;
(function (PlayerMove) {
    PlayerMove["small_1"] = "1-1";
    PlayerMove["small_2"] = "1-2";
    PlayerMove["medium_1"] = "2-1";
    PlayerMove["medium_2"] = "2-2";
    PlayerMove["large_1"] = "3-1";
    PlayerMove["large_2"] = "3-2";
})(PlayerMove || (PlayerMove = {}));
