var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Component } from "react";
import { Player, PlayerMove } from "../../common/src/model";
import { ClientCommand, ServerCommand, } from "../../common/src/transfer";
import "./Game.css";
import { ReactComponent as X } from "./X.svg";
import { ReactComponent as O } from "./O.svg";
var SERVER_SPEC = "35.188.94.49:8080";
function Square(props) {
    var contents = "";
    if (props.value) {
        var _a = props.value.split("-"), player = _a[0], size = _a[1], id = _a[2];
        contents = player === "X" ? _jsx(X, { className: "size-" + size }, void 0) : _jsx(O, { className: "size-" + size }, void 0);
    }
    return (_jsx("button", __assign({ className: "square", onClick: props.onClick }, { children: contents }), void 0));
}
var Board = /** @class */ (function (_super) {
    __extends(Board, _super);
    function Board() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Board.prototype.renderSquare = function (i) {
        var _this = this;
        return (_jsx(Square, { value: this.props.squares[i], onClick: function () { return _this.props.squareClick(i); } }, void 0));
    };
    Board.prototype.render = function () {
        return (_jsxs("div", { children: [_jsxs("div", __assign({ className: "board-row" }, { children: [this.renderSquare(0),
                        this.renderSquare(1),
                        this.renderSquare(2)] }), void 0),
                _jsxs("div", __assign({ className: "board-row" }, { children: [this.renderSquare(3),
                        this.renderSquare(4),
                        this.renderSquare(5)] }), void 0),
                _jsxs("div", __assign({ className: "board-row" }, { children: [this.renderSquare(6),
                        this.renderSquare(7),
                        this.renderSquare(8)] }), void 0)] }, void 0));
    };
    return Board;
}(Component));
var Game = /** @class */ (function (_super) {
    __extends(Game, _super);
    function Game(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            history: [
                {
                    squares: Array(9).fill(null),
                },
            ],
            playersTurn: Player.X,
            currentMoveIdx: 0,
            ws: null,
            myPlayer: null,
            remainingMoves: [
                PlayerMove.large_1,
                PlayerMove.large_2,
                PlayerMove.medium_1,
                PlayerMove.medium_2,
                PlayerMove.small_1,
                PlayerMove.small_2,
            ],
            selectedMove: null,
        };
        _this.handleClick = _this.handleClick.bind(_this);
        return _this;
    }
    Game.prototype.componentDidMount = function () {
        var _this = this;
        var gameId = this.props.match.params.gameId;
        (gameId === "new"
            ? fetch("http://" + SERVER_SPEC + "/new_room")
                .then(function (response) { return response.json(); })
                .then(function (data) { return data.roomId; })
            : Promise.resolve(gameId)).then(function (gameId) {
            window.history.replaceState(null, "Game", "/g/" + gameId);
            var webby = new WebSocket("ws://" + SERVER_SPEC + "/connect/" + gameId);
            webby.onmessage = function (event) {
                console.log(event);
                var msg = JSON.parse(event.data);
                if (msg.cmd) {
                    switch (msg.cmd) {
                        case ServerCommand.board:
                            var newBoardState = msg.payload.board;
                            _this.setState({
                                history: _this.state.history.concat([
                                    {
                                        squares: newBoardState,
                                    },
                                ]),
                                playersTurn: _this.state.playersTurn === Player.X ? Player.O : Player.X,
                                currentMoveIdx: _this.state.currentMoveIdx + 1,
                            });
                            break;
                        case ServerCommand.player:
                            _this.setState({
                                myPlayer: msg.payload.player,
                            });
                    }
                }
            };
            _this.setState({ ws: webby });
        });
    };
    Object.defineProperty(Game.prototype, "currentBoardState", {
        get: function () {
            return this.state.history[this.state.currentMoveIdx].squares;
        },
        enumerable: false,
        configurable: true
    });
    Game.prototype.isMovePossible = function (currentValue, proposedMove) {
        if (!currentValue) {
            return true;
        }
        var _a = currentValue.split("-"), existingPlayer = _a[0], existingSize = _a[1];
        var proposedSize = proposedMove.split("-")[0];
        // even if the size is larger we won't let you play over your own piece because that's dumb.
        if (existingPlayer === this.state.myPlayer) {
            return false;
        }
        return (parseInt(proposedSize) > parseInt(existingSize));
    };
    Game.prototype.handleClick = function (i) {
        // Users are not allowed to modify the past (see grandfather paradox).
        // TODO: warn users when no move is selected
        if (this.state.currentMoveIdx !== this.state.history.length - 1 ||
            this.state.playersTurn !== this.state.myPlayer ||
            !this.state.selectedMove) {
            return;
        }
        if (this.isMovePossible(this.currentBoardState[i], this.state.selectedMove)) {
            if (this.state.ws) {
                var clientMsg = { cmd: ClientCommand.move, payload: { location: i, player: this.state.playersTurn, move: this.state.selectedMove } };
                this.state.ws.send(JSON.stringify(clientMsg));
                var newRemainingMoves = __spreadArray([], this.state.remainingMoves);
                newRemainingMoves.splice(newRemainingMoves.indexOf(this.state.selectedMove), 1);
                this.setState({
                    selectedMove: null,
                    remainingMoves: newRemainingMoves,
                });
            }
        }
    };
    Game.prototype.calculateWinner = function (squares) {
        var lines = [
            [0, 1, 2],
            [3, 4, 5],
            [6, 7, 8],
            [0, 3, 6],
            [1, 4, 7],
            [2, 5, 8],
            [0, 4, 8],
            [2, 4, 6],
        ];
        for (var _i = 0, lines_1 = lines; _i < lines_1.length; _i++) {
            var line = lines_1[_i];
            var a = line[0], b = line[1], c = line[2];
            if (squares[a] && squares[b] && squares[c] &&
                squares[a][0] === squares[b][0] &&
                squares[b][0] === squares[c][0]) {
                return squares[a];
            }
        }
        return null;
    };
    Game.prototype.jumpTo = function (moveNum) {
        this.setState({
            currentMoveIdx: moveNum,
        });
    };
    Game.prototype.render = function () {
        var _this = this;
        var winner = this.calculateWinner(this.currentBoardState);
        var status;
        if (winner) {
            status = "Winner: " + winner[0];
        }
        else {
            status = "Next player: " + this.state.playersTurn;
        }
        var playerStatus = "I am: " + this.state.myPlayer;
        var moves = this.state.history.map(function (step, move) {
            var desc = move ? "Go to move #" + move : "Go to game start";
            return (_jsx("li", { children: _jsx("button", __assign({ onClick: function () { return _this.jumpTo(move); } }, { children: desc }), void 0) }, move));
        });
        var moveOptions = this.state.remainingMoves.map(function (move) {
            var size = move.split("-")[0];
            var classes = ["square"];
            if (_this.state.selectedMove === move) {
                classes.push("selected");
            }
            return (_jsx("div", __assign({ className: classes.join(" "), onClick: function () { _this.setState({ selectedMove: move }); } }, { children: _this.state.myPlayer === Player.X ? _jsx(X, { className: "size-" + size }, void 0) : _jsx(O, { className: "size-" + size }, void 0) }), move));
        });
        return (_jsxs("div", __assign({ className: "game" }, { children: [_jsxs("div", __assign({ className: "game-board" }, { children: [_jsx(Board, { squares: this.currentBoardState, xIsNext: this.state.playersTurn === Player.X, squareClick: this.handleClick }, void 0),
                        _jsx("div", { children: "Your Remaining Moves:" }, void 0),
                        _jsx("div", __assign({ className: "move-option-row" }, { children: moveOptions }), void 0)] }), void 0),
                _jsxs("div", __assign({ className: "game-info" }, { children: [_jsx("div", { children: status }, void 0),
                        _jsx("div", { children: playerStatus }, void 0),
                        _jsx("ol", { children: moves }, void 0)] }), void 0)] }), void 0));
    };
    return Game;
}(Component));
// ========================================
export default Game;
